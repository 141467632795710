import React, { ReactNode, useEffect, useState } from 'react';
import AirplayIcon from '@mui/icons-material/Airplay';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Divider, Grid, Link, Typography, Zoom } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import { formatDuration, formatPriceStart, formatTimeDuration } from 'utils/format';
import { routes } from 'utils/routing';
import { CountOfCategories } from 'utils/constants';
import { EventCategory } from 'api/admin/models';

import LocationIcon from 'components/svg/location.svg';
import VirtualIcon from 'components/svg/virtual.svg';
import Image from 'components/common/Image';
import Label from 'components/common/Label';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getSessionsGroups, MARKETS_SLUGS_MAP } from '../../../utils/transform';
import { DetectMarketSelectors } from '../../../store/selectors';
import LinkNoPropagate from '../LinkNoPropagate';

interface EventCardProps {
  id?: number;
  children?: ReactNode;
  imageLabel?: ReactNode;
  fab?: ReactNode;
  imagePopUp?: ReactNode;
  title: string;
  organizationTitle: string;
  organizationLogo: string;
  organization_mentions?: any[];
  settings?: any;
  startAt?: string;
  endAt?: string;
  timeZone?: string;
  imageUrl?: string;
  categories?: EventCategory[];
  targetAges?: any;
  currency?: string;
  minTicketPrice?: number;
  maxTicketPrice?: number;
  hasLocation?: boolean;
  isTest?: boolean;
  isVirtual?: boolean;
  isPopUpToggled?: boolean;
  eventRoute?: any;
  organizationRoute?: any;
  dateFirst?: boolean;
  hideDate?: boolean;
  noLocationLiveIcons?: boolean;
  padding?: number;
  sessions?: any;
}

const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;
const isTestEnv = () => BUILD_ENV === 'test';
const isProductionEnv = () => BUILD_ENV === 'production';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: `calc(100% + ${theme.spacing(3)})`,
    cursor: 'pointer',
  },
  clickable: {
    cursor: 'pointer',
  },
  imageContainer: {
    position: 'relative',
  },
  imageLabelContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  fabContainer: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: 0,
    top: 0,
    right: theme.spacing(2),
    zIndex: 2,
  },
  imagePopUpContainer: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
  },
  dateTypo: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  organizationTypo: {
    marginTop: theme.spacing(0.5),
    fontWeight: 500,
    textDecoration: 'unset',
  },
  organizationMentionsTypo: {
    fontWeight: 500,
  },
  link: {
    '&:not(:last-child)': {
      '&::after': {
        content: '","',
        paddingRight: 4,
      },
    },
  },
  wordBreak: {
    wordBreak: 'break-word',
    textDecoration: 'none',
  },
  titleContainer: {
    marginTop: 8,
  },
  orgWrapper: {
    marginTop: 4,
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '2px 8px',
  },
  orgTitleWrapper: {
    paddingTop: '0px !important',
  },
  orgImageContainer: {
    position: 'relative',
    minWidth: 28,
    minHeight: 28,
    paddingTop: '0px !important',
    paddingLeft: '0px !important',
    zIndex: 100,
    '.secondary &': {
      [theme.breakpoints.down('md')]: {
        width: 104,
        overflow: 'hidden',
        '& img': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      },
    },
  },
}));

const EventCard: React.FC<EventCardProps> = (props) => {
  const {
    id,
    children,
    imageLabel,
    fab,
    imagePopUp,
    isPopUpToggled,
    title,
    organizationTitle,
    organizationLogo,
    organization_mentions = [],
    isTest,
    startAt,
    endAt,
    timeZone,
    imageUrl,
    categories,
    targetAges,
    currency,
    minTicketPrice,
    maxTicketPrice,
    hasLocation,
    isVirtual,
    settings,
    eventRoute,
    organizationRoute,
    dateFirst,
    hideDate,
    noLocationLiveIcons,
    padding,
    sessions,
  } = props;
  const [t] = useTranslation();
  const { classes, cx } = useStyles();

  const hasOrganizationMentions = organization_mentions.length > 0;
  const sliceOfCategories = categories.slice(0, CountOfCategories);
  const router = useRouter();
  const [orgsToShow, setOrgsToShow] = useState([]);
  const [moreOrgs, setMoreOrgs] = useState([]);

  const time = startAt && endAt && formatTimeDuration(startAt, endAt, timeZone);
  const timesLabel = () => {
    if (Array.isArray(sessions) && sessions.length > 1) {
      return getSessionsGroups(sessions, timeZone).length > 1
        ? t('common:general.multipleDays')
        : t('common:general.multipleTimes');
    }
    return (
      <>
        {time.split('|')[0]} <span>{time.split('|')[1]}</span>
      </>
    );
  };

  useEffect(() => {
    let lines = 1;
    let lineWidth = 0;
    const preOrgs: any[] = [];
    const preMoreOrgs: any[] = [];
    organization_mentions.forEach((org) => {
      const orgWidth = org.organization.title.length * 7 + 9;
      if (lineWidth + orgWidth < 320) {
        lineWidth += orgWidth;
      } else {
        lines += 1;
        lineWidth = orgWidth;
      }
      if (lines > 3) {
        preMoreOrgs.push(org);
      } else preOrgs.push(org);
    });
    setOrgsToShow(preOrgs);
    setMoreOrgs(preMoreOrgs);
  }, [organization_mentions]);

  const market = useSelector(DetectMarketSelectors.getSelectedMarket);

  const isPriceTag = () => {
    if (id === 6047 && isProductionEnv() && !minTicketPrice && !maxTicketPrice) return '';
    if (id === 1953 && isTestEnv() && !minTicketPrice && !maxTicketPrice) return '';
    return true;
  };

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>, route: any) => {
    event.preventDefault();
    event.stopPropagation();
    router.push(route.href);
  };

  return (
    <Link href={eventRoute.link} underline="none">
      <Grid className={classes.root} container spacing={3} alignContent="space-between">
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.imageContainer}>
                <Zoom in={isPopUpToggled}>
                  <div className={classes.imagePopUpContainer}>{imagePopUp}</div>
                </Zoom>
                <Image
                  variant="rectangle"
                  rounded
                  src={imageUrl}
                  alt={title}
                  placeholder={isVirtual && !hasLocation ? <VirtualIcon /> : <LocationIcon />}
                />
                {fab && <div className={classes.fabContainer}>{fab}</div>}
                {imageLabel && <div className={classes.imageLabelContainer}>{imageLabel}</div>}
              </div>
            </Grid>
            {dateFirst && !hideDate && startAt && timeZone && (
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: 24,
                  paddingBottom: 0,
                  marginBottom: -8,
                  ...(padding && { paddingLeft: padding * 2, paddingRight: padding }),
                }}
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.dateTypo} color="primary" variant="body2">
                      {formatDuration(startAt, endAt, timeZone, true)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dateTypo} color="primary" variant="body2">
                      {timesLabel()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className={classes.titleContainer}
              style={{ ...(padding && { paddingLeft: padding * 2, paddingRight: padding }) }}
            >
              <Typography className={classes.wordBreak} color="textPrimary" variant="h6">
                {title}
              </Typography>
              <LinkNoPropagate href={organizationRoute.link} underline="none">
                <Typography className={classes.organizationTypo} color="textSecondary" variant="body2" component="p">
                  <Grid className={classes.orgWrapper} container spacing={1.5} alignItems="center">
                    <Grid item className={classes.orgImageContainer}>
                      <Image
                        variant="square"
                        objectFit="cover"
                        src={organizationLogo || '/static/img/defaultOrgLogo.png'}
                        alt={organizationTitle}
                        priority
                      />
                    </Grid>
                    <Grid item xs className={classes.orgTitleWrapper}>
                      <Typography color="textSecondary" variant="body2" style={{ fontWeight: 500 }}>
                        {t('common:general.by')} {organizationTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  {hasOrganizationMentions && 'and '}
                  {hasOrganizationMentions &&
                    orgsToShow.map((item) => {
                      const { title, id } = item.organization;
                      return (
                        <Typography
                          key={id}
                          className={cx(classes.link, classes.organizationTypo)}
                          color="textSecondary"
                          variant="body2"
                          component="span"
                          onClick={(event: React.MouseEvent<HTMLSpanElement>) =>
                            handleClick(event, routes.organizationProfile(id))
                          }
                        >
                          {title}
                        </Typography>
                      );
                    })}
                  {moreOrgs.length > 0 && `and +${moreOrgs.length} more`}
                </Typography>
              </LinkNoPropagate>
            </Grid>
            {!dateFirst && !hideDate && startAt && timeZone && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.dateTypo} color="primary" variant="body2">
                      {formatDuration(startAt, endAt, timeZone, true)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dateTypo} color="primary" variant="body2">
                      {timesLabel()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ ...(padding && { paddingLeft: padding * 2.5, paddingRight: padding, paddingBottom: 12 }) }}
        >
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs container spacing={1} alignItems="center">
              {categories.length !== 0 &&
                sliceOfCategories.map((category) => {
                  const route = routes.events({
                    category: category.id,
                    market_slug: MARKETS_SLUGS_MAP[market as keyof typeof MARKETS_SLUGS_MAP],
                    ordering: 'session_date',
                  });
                  return (
                    <Grid item key={category.id}>
                      <LinkNoPropagate href={route.link} underline="none">
                        <Label color="lightGray" textColor="darkGray" size="small" label={category.title} hasHover />
                      </LinkNoPropagate>
                    </Grid>
                  );
                })}
              {targetAges.map((targetAge: { title: string; id: string }) => {
                const route = routes.events({
                  target_age: targetAge.id,
                  market_slug: MARKETS_SLUGS_MAP[market as keyof typeof MARKETS_SLUGS_MAP],
                  ordering: 'session_date',
                });
                return (
                  <Grid item key={targetAge.id}>
                    <LinkNoPropagate href={route.link} underline="none">
                      <Label
                        color="extraLightPurple"
                        textColor="purple"
                        size="small"
                        label={targetAge?.title}
                        hasHover
                      />
                    </LinkNoPropagate>
                  </Grid>
                );
              })}
              {isPriceTag() && (
                <Grid item>
                  <LinkNoPropagate
                    href={
                      routes.events({
                        price: minTicketPrice === 0 ? 'free' : 'paid',
                        market_slug: MARKETS_SLUGS_MAP[market as keyof typeof MARKETS_SLUGS_MAP],
                        ordering: 'session_date',
                      }).link
                    }
                    underline="none"
                  >
                    <Label
                      color="green"
                      textColor="green"
                      variant="outlined"
                      size="small"
                      label={formatPriceStart(minTicketPrice || 0, maxTicketPrice || 0, currency, true, true)}
                      hasHover
                    />
                  </LinkNoPropagate>
                </Grid>
              )}
              {settings && !settings?.is_public && (
                <Grid item>
                  <Label
                    variant="outlined"
                    color="secondary"
                    textColor="secondary"
                    size="small"
                    label={t('common:general.privatUrl')}
                  />
                </Grid>
              )}
              {isTest && (
                <Grid item>
                  <Label
                    variant="outlined"
                    color="secondary"
                    textColor="secondary"
                    size="small"
                    label={t('common:general.testEvent')}
                  />
                </Grid>
              )}
            </Grid>
            {!noLocationLiveIcons && (hasLocation || isVirtual) && (
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item component={Box} lineHeight={0}>
                    {hasLocation && <LocationOnIcon style={{ color: '#383C44' }} />}
                    {isVirtual && <AirplayIcon style={{ color: '#383C44' }} />}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {children && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {children}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export default EventCard;
